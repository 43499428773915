import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { FetchProps } from 'civic-champs-shared/api/hooks'

export const useChangeEventScheduling = (
  props: FetchProps<void, void> = {
    errorMessage: 'Error updating scheduling information',
    successMessage: 'Successfully updated scheduling information',
    useNewNotification: true,
  },
): ((
  encodedOccurrences: string,
  body: { endDate?: string; startDate?: string; recurrencePattern?: string },
) => Promise<void>) => {
  const [request] = useFetchRefactored<void>(props)

  return useCallback(
    (encodedOccurrence: string, body: { endDate?: string; startDate?: string; recurrencePattern?: string }) =>
      request({
        method: 'put',
        url: `/opportunity_instances/${encodedOccurrence}/dates`,
        config: {
          body,
        },
      }),
    [request],
  )
}

export default useChangeEventScheduling
