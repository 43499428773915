import React, { useRef, useState } from 'react'
import HelpIcon from '@material-ui/icons/Help'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { Button, Popover } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useCurrentProfile, useCurrentUser } from 'auth/hooks'
import { ROLE_TO_NAME } from 'civic-champs-shared/auth/utils/permissions'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Auth } from 'aws-amplify'
import { HelpModal } from 'App/components/HelpModal'
import { usePageHeaderStyles } from 'App/hooks/usePageHeaderStyles'
import isNull from 'lodash/isNull'
import { useNotifications } from 'notification/hooks/useNotifications'
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff'
import cn from 'classnames'
import { NotificationPopper } from 'notification/components/NotificationPopper'

export interface PageHeaderProps {
  title: string
  subTitle?: string
  backLink?: string
  noPadding?: boolean
  children: React.ReactNode
  error?: string
}

export function PageHeaderButtons() {
  const classes = usePageHeaderStyles()
  const currentUser = useCurrentUser()
  const currentProfile = useCurrentProfile()
  const [helpModalOpen, setHelpModalOpen] = useState(false)
  const [profileExpanded, setProfileExpanded] = useState<boolean>(false)
  const [modalRef, setModalRef] = React.useState<null | HTMLElement>(null)
  const notificationModalOpen = Boolean(modalRef)
  const avatarUrl = (!isNull(currentProfile) && currentProfile?.user.avatar?.url) || '/assets/icons/profile.svg'
  // @ts-ignore
  const role = ROLE_TO_NAME[currentUser?.permissions?.role]
  const anchorEl = useRef<any>()
  const { unread, markAllRead, deleteAll, deleteOne, notifications } = useNotifications()
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    !modalRef && markAllRead()
    setModalRef(modalRef ? null : event.currentTarget)
  }

  return (
    <div className={classes.headerButtons}>
      <Button
        classes={{ root: classes.helpButton, startIcon: classes.helpIcon, label: classes.helpLabel }}
        startIcon={<HelpIcon />}
        onClick={() => setHelpModalOpen(true)}
      >
        Help
      </Button>
      <Button
        classes={{
          root: classes.notificationButton,
          startIcon: cn(classes.notificationIcon, {
            [classes.unread]: currentProfile?.notificationSettings?.enabled && unread,
          }),
          label: classes.notificationLabel,
        }}
        startIcon={currentProfile?.notificationSettings?.enabled ? <NotificationsIcon /> : <NotificationsOffIcon />}
        onClick={handleClick}
      >
        <></>
      </Button>
      <NotificationPopper
        open={notificationModalOpen}
        onClose={() => setModalRef(null)}
        anchorEl={modalRef}
        notifications={notifications}
        onDelete={deleteOne}
        onDeleteAll={deleteAll}
      />
      <div className={classes.userProfile} ref={anchorEl} onClick={() => setProfileExpanded(open => !open)}>
        <div className={classes.profileLeft}>
          <div style={{ backgroundImage: `url("${avatarUrl}")` }} className={classes.avatar}></div>
          <div className={classes.nameRole}>
            <p className={classes.name}>
              {currentUser.givenName} {currentUser.familyName}
            </p>
            <p className={classes.role}>{role}</p>
          </div>
        </div>
        <div className={classes.openClose}>{profileExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</div>
      </div>
      <Popover
        id="profile-popover"
        classes={{ paper: classes.popover }}
        open={profileExpanded}
        anchorEl={anchorEl.current}
        onClose={() => setProfileExpanded(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 15,
          horizontal: 'right',
        }}
      >
        <Link to="/my/account">My profile</Link>
        <a href="#" onClick={() => Auth.signOut()}>
          Log Out
        </a>
      </Popover>
      <HelpModal open={helpModalOpen} onClose={() => setHelpModalOpen(false)} />
    </div>
  )
}
