import * as yup from 'yup'
import { DateSchema } from 'yup'
import { RECURRING_TYPE } from '../../civic-champs-shared/core/utils/constants/RECURRING_TYPE_OPTIONS'
import moment from 'moment'
import { SIMPLE_RECURRING_TYPE } from '../../civic-champs-shared/core/utils/constants/SIMPLE_RECURRING_TYPE_OPTIONS'

export const eventDatePayloadSchema = yup.object({
  startDate: yup
    .date()
    .required('A start date is required. Please enter a start date.')
    .typeError('A start date is required. Please enter a start date.'),
  startTime: yup
    .date()
    .required('A start time is required. Please enter a start time.')
    .typeError('A start time is required. Please enter a start time.'),
  endDate: yup
    .date()
    .typeError('A end date is required. Please enter a end date.')
    .when(
      ['recurringType', 'isSchedulable'],
      (recurringType: RECURRING_TYPE, isSchedulable: boolean, schema: DateSchema) => {
        if (!isSchedulable || recurringType === RECURRING_TYPE.NEVER) return schema.nullable().notRequired()
        return schema
          .test('min-by-date', 'The end date cannot occur before the start date.', function (value: any) {
            return value && moment(value).isSameOrAfter(moment(this.parent.startDate), 'day')
          })
          .required('A end date is required. Please enter a end date.')
      },
    ),
  endTime: yup
    .date()
    .test('min-by-time', 'The end time cannot occur before the start time.', function (value: any) {
      if (!this.parent.startDate || !this.parent.startTime || !this.parent.endDate || !value) return true
      const startDate = moment(this.parent.startDate)
      const startTime = moment(this.parent.startTime).year(startDate.year()).dayOfYear(startDate.dayOfYear())
      const endDate = moment(this.parent.endDate)
      const endTime = moment(value).year(endDate.year()).dayOfYear(endDate.dayOfYear())
      return value && endTime.isSameOrAfter(startTime)
    })
    .required('A end time is required. Please enter a end time.')
    .typeError('A end time is required. Please enter a end time.'),
  roles: yup
    .array()
    .of(
      yup.object({
        roleId: yup.number().typeError('Please select a Role').required('Please select a Role'),
        quantity: yup
          .number()
          .typeError('Registration limit is required')
          // @ts-ignore
          .id('Registration limit is required')
          .required('Registration limit is required'),
      }),
    )
    .notRequired(),
})

export const simpleEventDatePayloadSchema = yup.object({
  startTime: yup
    .date()
    .required('A start time is required. Please enter a start time.')
    .typeError('A start time is required. Please enter a start time.'),
  endDate: yup
    .date()
    .typeError('A end date is required. Please enter a end date.')
    .when(
      ['simpleRecurringType', 'isSchedulable'],
      (simpleRecurringType: SIMPLE_RECURRING_TYPE, isSchedulable: boolean, schema: DateSchema) => {
        if (!isSchedulable || simpleRecurringType === SIMPLE_RECURRING_TYPE.SELECTED_DATES)
          return schema.nullable().notRequired()
        return schema
          .test('min-by-date', 'The end date cannot occur before the start date.', function (value: any) {
            return value && moment(value).isSameOrAfter(moment(this.parent.startDate), 'day')
          })
          .required('A end date is required. Please enter a end date.')
      },
    ),
  endTime: yup
    .date()
    .test('min-by-time', 'The end time cannot occur before the start time.', function (value: any) {
      if (!this.parent.startDate || !this.parent.startTime || !this.parent.endDate || !value) return true
      const startDate = moment(this.parent.startDate)
      const startTime = moment(this.parent.startTime).year(startDate.year()).dayOfYear(startDate.dayOfYear())
      const endDate = moment(this.parent.endDate)
      const endTime = moment(value).year(endDate.year()).dayOfYear(endDate.dayOfYear())
      return value && endTime.isSameOrAfter(startTime)
    })
    .required('A end time is required. Please enter a end time.')
    .typeError('A end time is required. Please enter a end time.'),
  roles: yup
    .array()
    .of(
      yup.object({
        roleId: yup.number().typeError('Please select a Role').required('Please select a Role'),
        quantity: yup
          .number()
          .typeError('Registration limit is required')
          // @ts-ignore
          .id('Registration limit is required')
          .required('Registration limit is required'),
      }),
    )
    .notRequired(),
  dates: yup
    .array()
    .of(yup.date())
    .when(['isSchedulable'], (isSchedulable: boolean, schema: DateSchema) => {
      if (!isSchedulable) {
        return schema.nullable().notRequired()
      } else {
        return schema
          .required('An event date is required. Please enter an event date.')
          .typeError('An event date is required. Please enter an event date.')
      }
    }),
})

export const editEventRolesPayloadSchema = yup.object({
  roles: yup
    .array()
    .of(
      yup.object({
        roleId: yup.number().typeError('Please select a Role').required('Please select a Role'),
        quantity: yup
          .number()
          .typeError('Registration limit is required')
          // @ts-ignore
          .id('Registration limit is required')
          .required('Registration limit is required'),
      }),
    )
    .notRequired(),
})
