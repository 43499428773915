import { Field } from 'formik'
import {
  StyledKeyboardDatePicker,
  StyledKeyboardTimePicker,
} from '../../../civic-champs-shared/formik/components/StyledFormikDateTimePickers'
import React from 'react'
import { useAddEditEventStyles } from '../../hooks/useAddEditEventStyles'

export function SchedulableEditBlock() {
  const classes = useAddEditEventStyles()

  return (
    <>
      <div className={classes.dateTimePickers}>
        <Field
          inputVariant="outlined"
          label="Event Date"
          higher
          component={StyledKeyboardDatePicker}
          name="startDate"
          fullWidth={true}
          minDate={new Date()}
        />
      </div>

      <div className={classes.dateTimePickers}>
        <div className={classes.dateTimePicker}>
          <Field
            inputVariant="outlined"
            label="Start Time"
            higher
            component={StyledKeyboardTimePicker}
            name="startTime"
          />
        </div>

        <div className={classes.dateTimePicker}>
          <Field higher inputVariant="outlined" label="End Time" component={StyledKeyboardTimePicker} name="endTime" />
        </div>
      </div>
    </>
  )
}
