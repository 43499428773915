import { Grid, Tooltip, Typography } from '@material-ui/core'
import { ReactComponent as VerifiedIcon } from 'images/verified.svg'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import PhoneIcon from '@material-ui/icons/Phone'
import HomeIcon from '@material-ui/icons/Home'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { IconComponent } from 'civic-champs-shared/core/icon'
import format from 'civic-champs-shared/utils/format'
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import { Cell } from 'react-table'
import { createMarkup } from '../../../civic-champs-shared/core/utils/helpers'
import { SendMethod } from 'civic-champs-shared/question-sets/types/invitation'

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    borderRadius: '5px',
    border: '1px #E0E3E3 solid',
    padding: '8px',
  },
  tooltipHeader: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
  arrow: {
    color: '#fff',
  },
  cellGrid: { fontSize: '20px', width: '100px' },
  phoneEmailCellGrid: { fontSize: '20px', width: '40px' },
  verifiedBlock: {
    width: '100%',
    whiteSpace: 'nowrap',
    position: 'relative',
  },
  bounced: {
    color: theme.palette.error.main,
    marginBottom: '5px',
  },
  doNotContact: {
    color: theme.palette.error.main,
  },
  invalid: {
    fontSize: '9px',
  },
  isVerified: {
    paddingRight: '28px',
  },
  verifiedIcon: {
    position: 'absolute',
    right: '-20px',
    top: 0,
    color: '#0F5DB5',
    '&$doNotContact': {
      color: theme.palette.error.main,
    },
  },

  wrap: {
    whiteSpace: 'normal',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  expanded: {
    // median size of collapsed group list that doesn't fit into 40 chars
    minWidth: '244px',
    paddingTop: '12px',
    paddingBottom: '12px',
    '&$noPadding': {
      padding: 0,
    },
  },
  noPadding: {},
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  contactIcon: { fontSize: 'inherit', color: '#0F5DB5', cursor: 'default' },
  transparent: {
    opacity: 0,
  },
  expandColumn: {
    width: '0',
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 10px',
  },
  subRowExpandColumn: {
    width: '0',
    height: '26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px 10px',
  },
}))

export const contactSort = (rowA: any, rowB: any): number => {
  const contactA = `${rowA.values.lastName} ${rowA.values.firstName}`.toLowerCase().trim()
  const contactB = `${rowB.values.lastName} ${rowB.values.firstName}`.toLowerCase().trim()

  return contactA.localeCompare(contactB)
}

export const ContactTooltip = ({ value, children }: any) => {
  const {
    email,
    emailVerified,
    phoneNumber,
    phoneNumberVerified,
    homePhoneNumber,
    address,
    emergencyContact,
    emergencyContactRelationship,
    emailBounced,
    phoneNumberBounced,
    preferredContact,
  } = value

  const doNotContact = preferredContact?.sendMethod === SendMethod.DO_NOT_CONTACT

  const classes = useStyles()

  if (!email && !phoneNumber && !homePhoneNumber && !address && !emergencyContact) {
    return null
  }

  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      title={
        <>
          {(email || phoneNumber || homePhoneNumber || address) && (
            <>
              <Typography className={classes.tooltipHeader}>Contact Information</Typography>
              <Grid container spacing={0}>
                {email && (
                  <Grid container item xs={11} justify="space-between">
                    <div
                      className={cn(classes.verifiedBlock, {
                        [classes.isVerified]: emailVerified,
                        [classes.bounced]: emailBounced || doNotContact,
                      })}
                    >
                      {email}{' '}
                      {emailVerified && (
                        <VerifiedIcon className={cn(classes.verifiedIcon, { [classes.doNotContact]: doNotContact })} />
                      )}
                      {(emailBounced || doNotContact) && (
                        <>
                          <br />
                          <span className={classes.invalid}>
                            Invalid: {doNotContact ? 'Do Not Contact' : 'bounced'}
                          </span>
                        </>
                      )}
                    </div>
                  </Grid>
                )}
                {phoneNumber && (
                  <>
                    <Grid item xs={4} className={cn({ [classes.doNotContact]: doNotContact })}>
                      Mobile phone:
                    </Grid>
                    <Grid container item xs={7} justify="space-between">
                      <div
                        className={cn(classes.verifiedBlock, {
                          [classes.isVerified]: phoneNumberVerified,
                          [classes.bounced]: phoneNumberBounced || doNotContact,
                        })}
                      >
                        {format.phoneNumber(phoneNumber)}{' '}
                        {phoneNumberVerified && (
                          <VerifiedIcon
                            className={cn(classes.verifiedIcon, { [classes.doNotContact]: doNotContact })}
                          />
                        )}
                        {(phoneNumberBounced || doNotContact) && (
                          <>
                            <br />
                            <span className={classes.invalid}>
                              Invalid: {doNotContact ? 'Do Not Contact' : 'bounced'}
                            </span>
                          </>
                        )}
                      </div>
                    </Grid>
                  </>
                )}
                {homePhoneNumber && (
                  <>
                    <Grid item xs={4}>
                      Home phone:
                    </Grid>
                    <Grid item xs={7}>
                      {format.phoneNumber(homePhoneNumber)}
                    </Grid>
                  </>
                )}
                {address && (
                  <>
                    <Grid item xs={4}>
                      Address:
                    </Grid>
                    <Grid item xs={7}>
                      {format.address(address)}
                    </Grid>
                  </>
                )}
              </Grid>
            </>
          )}
          {emergencyContact && (
            <>
              <Typography className={classes.tooltipHeader}>Emergency Contact</Typography>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={4}>
                      Name:
                    </Grid>
                    <Grid item xs={7}>
                      {format.name(emergencyContact)}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={4}>
                      Relationship:
                    </Grid>
                    <Grid item xs={7}>
                      {emergencyContactRelationship}
                    </Grid>
                  </Grid>
                </Grid>
                {[
                  { key: 'phoneNumber', title: 'Mobile', type: 'phone' },
                  { key: 'workPhoneNumber', title: 'Work', type: 'phone' },
                  { key: 'homePhoneNumber', title: 'Home', type: 'phone' },
                  { key: 'email', title: 'Email' },
                ].map(({ key, title, type }) =>
                  emergencyContact[key] ? (
                    <Grid item xs={12} key={key}>
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          {title}:
                        </Grid>
                        <Grid item xs={7}>
                          {type === 'phone' ? format.phoneNumber(emergencyContact[key]) : emergencyContact[key]}
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null,
                )}
              </Grid>
            </>
          )}
        </>
      }
      placement="right-start"
      arrow
      interactive
    >
      {children}
    </Tooltip>
  )
}

export const ColoredIcon = ({ Component, verified, bounced, display, ...props }: any) => {
  return (
    <Component
      {...props}
      htmlColor={bounced ? '#BA1B1B' : verified ? '#0F5DB5' : '#C4C7C7'}
      fontSize="inherit"
      style={{ opacity: display ? 1 : 0 }}
    />
  )
}

export const ContactCell = ({ value }: Cell<any>) => {
  const classes = useStyles()
  const {
    email,
    emailVerified,
    phoneNumber,
    phoneNumberVerified,
    address,
    emergencyContact,
    emailBounced,
    phoneNumberBounced,
    preferredContact,
  } = value

  const doNotContact = preferredContact?.sendMethod === SendMethod.DO_NOT_CONTACT

  return (
    <ContactTooltip value={value}>
      <Grid container className={classes.cellGrid} justify="space-between">
        <Grid item>
          <ColoredIcon
            Component={AlternateEmailIcon}
            bounced={emailBounced || doNotContact}
            verified={emailVerified}
            display={!!email}
          />
        </Grid>
        <Grid item>
          <ColoredIcon
            Component={PhoneIcon}
            bounced={phoneNumberBounced || doNotContact}
            verified={phoneNumberVerified}
            display={!!phoneNumber}
          />
        </Grid>
        <Grid item>
          <ColoredIcon Component={HomeIcon} verified display={!!address} />
        </Grid>
        <Grid item>
          <span
            className={cn('material-symbols-outlined', classes.contactIcon, {
              [classes.transparent]: !emergencyContact,
            })}
          >
            emergency
          </span>
        </Grid>
      </Grid>
    </ContactTooltip>
  )
}

export const PhoneEmailContactCell = ({ value }: any) => {
  const classes = useStyles()
  const { email, emailVerified, phoneNumber, phoneNumberVerified, emailBounced, phoneNumberBounced } = value
  return (
    <ContactTooltip value={value}>
      <Grid container className={classes.phoneEmailCellGrid} justify="space-between">
        <Grid item>
          <ColoredIcon
            Component={AlternateEmailIcon}
            bounced={emailBounced}
            verified={emailVerified}
            display={!!email}
          />
        </Grid>
        <Grid item>
          <ColoredIcon
            Component={PhoneIcon}
            bounced={phoneNumberBounced}
            verified={phoneNumberVerified}
            display={!!phoneNumber}
          />
        </Grid>
      </Grid>
    </ContactTooltip>
  )
}

export const ConsolidatedContactCell = ({ value }: any) => (
  <ContactTooltip value={value}>
    <Grid container justify="center">
      <Grid item>
        <IconComponent name="contact" customColor="#0F5DB5" height={18} width={18} />
      </Grid>
    </Grid>
  </ContactTooltip>
)

export const NameCell = (input: any, _?: any, userField: string = 'user') => {
  const {
    value,
    row: {
      original: {
        [userField]: { id },
      },
    },
  } = input
  const classes = useStyles()
  return (
    <Link className={classes.link} to={`/champion/${id}`}>
      {value}
    </Link>
  )
}

export const PersonLinkCell = ({ value }: any) => {
  const { id, givenName, familyName } = value || {}
  const classes = useStyles()

  return id ? (
    <Link className={classes.link} to={`/champion/${id}`}>
      {givenName} {familyName}
    </Link>
  ) : null
}

export const LegacyExpandRowCell = ({ row }: any) => {
  const classes = useStyles()
  const Expand = row.isExpanded ? ExpandLessIcon : ExpandMoreIcon
  return (
    <span
      className={cn(row.canExpand ? classes.expandColumn : classes.subRowExpandColumn)}
      {...row.getToggleRowExpandedProps()}
    >
      {row.canExpand && <Expand width={42} height={42} />}
    </span>
  )
}

export const ExpandRowCell = ({ row }: any) => {
  const classes = useStyles()
  return (
    <span
      className={cn(row.canExpand ? classes.expandColumn : classes.subRowExpandColumn)}
      {...row.getToggleRowExpandedProps()}
    >
      {row.canExpand && (
        <ExpandMoreIcon style={row.isExpanded ? {} : { transform: 'rotate(-90deg)' }} width={42} height={42} />
      )}
    </span>
  )
}

export const ExpandableCell = ({
  value,
  maxLength = 45,
  noPadding = false,
}: {
  value: string
  maxLength?: number
  noPadding?: boolean
}) => {
  const classes = useStyles()
  const expandable = value?.length > maxLength
  const [expanded, setExpanded] = useState<boolean>(false)
  return (
    <div
      className={cn({
        [classes.wrap]: expanded,
        [classes.noWrap]: !expanded,
        [classes.expanded]: expanded,
        [classes.noPadding]: noPadding,
      })}
    >
      {!expandable ? (
        value
      ) : expanded ? (
        <>
          {value}{' '}
          {
            <a href="#" className={classes.link} onClick={() => setExpanded(false)}>
              (show less)
            </a>
          }
        </>
      ) : (
        <>
          {value.substr(0, maxLength - 15)}...{' '}
          {
            <a href="#" className={classes.link} onClick={() => setExpanded(true)}>
              (show more)
            </a>
          }
        </>
      )}
    </div>
  )
}

export const HtmlExpandableCell = ({ value, maxLength = 45 }: { value: string; maxLength?: number }) => {
  const classes = useStyles()
  const textValue = value.replace(/<[^>]*>?/gm, '')
  const expandable = textValue?.length > maxLength
  const [expanded, setExpanded] = useState<boolean>(false)
  return expandable ? (
    <div
      className={cn({
        [classes.wrap]: expanded,
        [classes.noWrap]: !expanded,
        [classes.expanded]: expanded,
      })}
    >
      {expandable &&
        (expanded ? (
          <>
            <div dangerouslySetInnerHTML={createMarkup(value)} />
            <a href="#" className={classes.link} onClick={() => setExpanded(false)}>
              (show less)
            </a>
          </>
        ) : (
          <>
            {textValue.substr(0, maxLength - 15)}...{' '}
            {
              <a href="#" className={classes.link} onClick={() => setExpanded(true)}>
                (show more)
              </a>
            }
          </>
        ))}
    </div>
  ) : (
    <div
      className={cn({
        [classes.wrap]: expanded,
        [classes.noWrap]: !expanded,
        [classes.expanded]: expanded,
      })}
      dangerouslySetInnerHTML={createMarkup(value)}
    />
  )
}
