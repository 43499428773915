import React from 'react'
import { Link } from 'react-router-dom'
import { usePageHeaderStyles } from 'App/hooks/usePageHeaderStyles'
import { muiTheme } from 'theme'
import { ThemeProvider } from '@material-ui/styles'
import cn from 'classnames'
import { PageHeaderButtons } from './PageHeaderButtons'

export interface PageHeaderProps {
  title: string
  subTitle?: string
  backLink?: string
  noPadding?: boolean
  bottomSpaceForHubspotWidget?: boolean
  children: React.ReactNode
  error?: string
}

function PageHeaderComponent({
  title,
  subTitle,
  backLink,
  children,
  noPadding,
  bottomSpaceForHubspotWidget,
  error,
}: PageHeaderProps) {
  const classes = usePageHeaderStyles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.titles}>
          <h1 className={classes.title}>{title || ''}</h1>
          {backLink ? (
            <Link className={classes.backLink} to={backLink}>
              {subTitle}
            </Link>
          ) : (
            <p className={classes.subTitle}>{subTitle || '\u00A0'}</p>
          )}
        </div>
        <PageHeaderButtons />
      </div>
      <div
        className={cn(classes.childrenWrapper, {
          [classes.noPadding]: noPadding,
          [classes.bigBottomPadding]: bottomSpaceForHubspotWidget,
        })}
      >
        {error ? <p className={cn(classes.title, classes.error)}>{error}</p> : children}
      </div>
    </div>
  )
}

export default function PageHeader(props: PageHeaderProps) {
  return (
    <ThemeProvider theme={muiTheme}>
      <PageHeaderComponent {...props} />
    </ThemeProvider>
  )
}
