import { Field } from 'formik'
import {
  StyledKeyboardDatePicker,
  StyledKeyboardTimePicker,
} from '../../../civic-champs-shared/formik/components/StyledFormikDateTimePickers'
import AdvancedAutocomplete from '../../../civic-champs-shared/core/AdvancedAutocomplete'
import {
  RECURRING_TYPE,
  RECURRING_TYPE_OPTION,
  RECURRING_TYPE_OPTIONS,
} from '../../../civic-champs-shared/core/utils/constants/RECURRING_TYPE_OPTIONS'
import moment from 'moment'
import React from 'react'
import { useAddEditEventStyles } from '../../hooks/useAddEditEventStyles'
import { filter } from 'lodash'
import { EventDateScreenValues } from '../EventDateAndRoleScreen'

interface Props {
  values: EventDateScreenValues
  setFieldValue: (field: keyof EventDateScreenValues, value: any, shouldValidate?: boolean) => void
}

export function LegacySchedulableBlock({ values, setFieldValue }: Props) {
  const recurringOptions = filter(
    RECURRING_TYPE_OPTIONS(values.startDate || new Date(), true),
    ({ value }) => value !== RECURRING_TYPE.CUSTOM_EVENTS,
  )
  const classes = useAddEditEventStyles()
  return (
    <>
      <div className={classes.dateTimePickers}>
        <div className={classes.dateTimePicker}>
          <Field
            inputVariant="outlined"
            label="Start Date *"
            higher
            component={StyledKeyboardDatePicker}
            name="startDate"
          />
        </div>
      </div>

      <div className={classes.dateTimePickers}>
        <div className={classes.dateTimePicker}>
          <Field
            inputVariant="outlined"
            label="Start Time *"
            higher
            component={StyledKeyboardTimePicker}
            name="startTime"
          />
        </div>

        <div className={classes.dateTimePicker}>
          <Field
            higher
            inputVariant="outlined"
            label="End Time *"
            component={StyledKeyboardTimePicker}
            name="endTime"
          />
        </div>
      </div>
      <div className={classes.dateTimePickers}>
        <AdvancedAutocomplete<RECURRING_TYPE_OPTION>
          label={'Recurrence'}
          options={recurringOptions}
          disabled={!values.startDate}
          value={recurringOptions.find(({ value }) => value === values.recurringType)}
          getOptionInfo={option => ({
            left: option.label,
            id: option.value,
          })}
          disableOptionsAutoFiltering
          disableInputFilter
          clearable={false}
          onChange={option => setFieldValue('recurringType', (option as RECURRING_TYPE_OPTION).value)}
        />
      </div>
      <div className={classes.dateTimePickers}>
        <div className={classes.dateTimePicker}>
          {values.recurringType !== RECURRING_TYPE.NEVER && (
            <Field
              higher
              inputVariant="outlined"
              label="End Date *"
              component={StyledKeyboardDatePicker}
              name="endDate"
              minDate={moment(values.startDate || undefined)
                .startOf('day')
                .toDate()}
              minDateMessage={'The end date cannot occur before the start date.'}
            />
          )}
        </div>
      </div>
    </>
  )
}
