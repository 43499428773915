import { OpportunityResponseWithRecurrenceInfo } from '../../../interfaces/interfaceCreateEditEvent'
import { useMemo } from 'react'
import { RECURRING_TYPE, RECURRING_TYPE_OPTIONS } from 'civic-champs-shared/core/utils/constants/RECURRING_TYPE_OPTIONS'
import moment from 'moment-timezone'
import { useTimeZone } from '../../../../civic-champs-shared/auth/hooks'
import { useFeatureEnabled } from '../../../../core/feature/hooks'

export const useDateStrings = ({
  event,
  loading,
}: {
  event: OpportunityResponseWithRecurrenceInfo
  loading?: boolean
}) => {
  const isSimplifyRecurringEventRulesEnabled = useFeatureEnabled('SimplifyRecurringEventRules')
  const companyTimeZone = useTimeZone()
  const offersEnabled = useFeatureEnabled('StandaloneOffers')
  return useMemo(() => {
    if (loading || !event || !event.recurrenceInfo || event?.isOffer || (offersEnabled && !event?.endsAt)) {
      return event?.isOffer || (offersEnabled && !event?.endsAt)
        ? {
            recurrenceStart: moment(event.startsAt).tz(companyTimeZone).format('dddd, MMMM Do YYYY, hh:mma z'),
            recurrenceEnd: event.endsAt
              ? moment(event.endsAt).tz(companyTimeZone).format('dddd, MMMM Do YYYY, hh:mma z')
              : 'Never',
          }
        : { recurrenceString: '', opportunityTimeDisplay: '' }
    }
    const options = RECURRING_TYPE_OPTIONS(
      typeof event.startsAt === 'string' ? event.startsAt : event.startsAt.toString(),
    )
    const recurrenceOption = options.find(x => x.value === event.recurrenceInfo.type)
    const isRecurring = event.recurrenceInfo.type !== RECURRING_TYPE.NEVER
    const timeZone = event.recurrenceInfo.timeZone || companyTimeZone
    const timeRange = `${moment(event.startsAt).tz(timeZone).format('h:mm a')} -
    ${moment(event.endsAt).tz(timeZone).format('h:mm a z')}`
    const instanceStartString = moment(event.startsAt).tz(timeZone).format('dddd, MMMM Do')
    const start = moment(event.recurrenceInfo.startDate).tz(timeZone)
    const end = moment(event.recurrenceInfo.endDate).tz(timeZone)
    const startString = start.format('MMMM Do')
    const endString = end.format('MMMM Do')
    const recurrenceStart = start.format('dddd, MMMM Do, YYYY')
    const recurrenceEnd = end.format('dddd, MMMM Do, YYYY')
    const recurrencePattern = isSimplifyRecurringEventRulesEnabled
      ? event.recurrenceInfo.frequencyText
      : recurrenceOption && recurrenceOption.label

    const recurrenceString = isRecurring ? `${recurrencePattern} from ${startString} until ${endString}` : ''
    const opportunityTimeDisplay = `${instanceStartString} at ${timeRange}`
    return {
      recurrenceString,
      opportunityTimeDisplay,
      instanceStartString,
      timeRange,
      recurrenceStart,
      recurrenceEnd,
      recurrencePattern,
    }
  }, [companyTimeZone, event, isSimplifyRecurringEventRulesEnabled, loading, offersEnabled])
}
