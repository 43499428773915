export enum SIMPLE_RECURRING_TYPE {
  SELECTED_DATES = 'Selected Days Only',
  WEEKLY = 'Repeats Weekly',
  MONTHLY = 'Repeats Monthly',
}

export enum MONTHLY_RECURRING_TYPE {
  RESPECT_DATE = 'Respect Date',
  RESPECT_DAY_OF_WEEK = 'Respect Day of Week',
}
