import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { capitalize, get } from 'lodash'
import { Box, Button, Tooltip } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { Icon } from '@mdi/react'
import { mdiCheckDecagram } from '@mdi/js'
import isNull from 'lodash/isNull'
import format from 'civic-champs-shared/utils/format'
import { openMailToInNewTab } from '../utils'
import { useCurrentOrg } from '../../../auth/hooks'
import moment from 'moment'

export const DateTimeCell = ({ cell }) => format.datetime(cell.value)
export const TimeCell = ({ cell }) => format.time(cell.value)
export const useTimeInOrgTimezoneCell = () => {
  const org = useCurrentOrg()
  return useCallback(
    ({ cell }) => {
      return cell.value ? moment.tz(cell.value, org.timeZone).format('h:mm a') : ''
    },
    [org.timeZone],
  )
}
export const HoursCell = ({ cell }) => format.hours(cell.value)
export const PercentageCell = ({ cell }) => `${format.fixedPoint(cell.value, 1)}%`
export const CheckMarkCell = ({ cell }) => (cell.value && cell.value !== 'false' ? <Check /> : '')
export const NullableTextCell = ({ cell, className = '' }) => <div className={className}>{cell.value || '-'}</div>

export const EmailCell = ({ cell, verified = false }) => {
  return cell.value ? (
    <Box display="flex">
      <Tooltip title={`${cell.value}`}>
        <Box display="inline" flex="initial" pr={1} className="truncated">
          <a onClick={e => openMailToInNewTab(cell.value, e)} href={cell.value}>
            {cell.value}
          </a>
        </Box>
      </Tooltip>

      {verified && (
        <Tooltip title="Email verified">
          <Box component="span" ml="auto">
            <Icon path={mdiCheckDecagram} size={0.8} />
          </Box>
        </Tooltip>
      )}
    </Box>
  ) : (
    <span>-</span>
  )
}

export const WithLinkCell =
  (Cell, idToLinkFn, key = 'id') =>
  ({ linkProps = {}, ...props }) => {
    const { cell } = props
    return (
      <Link {...linkProps} to={idToLinkFn(!isNull(key) ? get(cell.row.original, key) : cell.row.original)}>
        <Cell {...props} />
      </Link>
    )
  }

// Custom `<Link>` components must implement click-to-navigate functions themselves
// https://github.com/remix-run/react-router/issues/7175
// https://github.com/remix-run/react-router/blob/b77283cb75efc909c34e48c7cdcb7f50281f4055/packages/react-router-dom/modules/Link.js#L35
export const LinkButton = ({ navigate, onClick, ...rest }) => {
  const props = {
    ...rest,
    onClick: event => {
      try {
        if (onClick) onClick(event)
      } catch (ex) {
        event.preventDefault()
        throw ex
      }

      //TODO? handle modified keys pressed during click?
      if (!event.defaultPrevented && event.button === 0 && !isModifiedEvent(event)) {
        event.preventDefault()
        navigate()
      }
    },
  }

  return <Button {...props} />
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}

//TODO move `<LinkButton>` to its own file?  Convert to a withNavOnClick() helper?
export const ViewCell =
  (idToLinkFn, key = 'id', label = 'View') =>
  ({ cell, navigate }) =>
    (
      <Link
        to={idToLinkFn(get(cell.row.original, key))}
        component={LinkButton}
        variant="text"
        style={{ color: '#5C8DE8' }}
      >
        {label}
      </Link>
    )

export const RightAlign = ({ children }) => <div style={{ textAlign: 'right', marginRight: '10px' }}>{children}</div>
export const LeftAlign = ({ children, width }) => <div style={{ textAlign: 'left', width: width }}>{children}</div>
export const DatePropertyCell = ({ cell }) => format.date(cell.value)
export const DateCell = ({ cell, className = '' }) => <div className={className}>{format.date(cell.value)}</div>
export const NullableDateCell = ({ cell, className = '' }) =>
  cell.value === null ? <div className={className}>N/A</div> : DateCell({ cell, className })
export const NumberCell = ({ cell }) => format.number(cell.value)
export const CapitalizeCell = ({ cell }) => (cell.value ? capitalize(cell.value) : '')
export const CellWithTooltip = ({ cell }) => (
  <Tooltip title={cell.value}>
    <Box flex={1} pr={1} display="inline" className="truncated">
      {cell.value}
    </Box>
  </Tooltip>
)

export const PhoneNumberCell = ({ cell, verified = false }) => {
  const phoneNumber = format.phoneNumber(cell.value)

  return cell.value ? (
    <Box display="flex">
      <Tooltip title={phoneNumber}>
        <Box flex={1} pr={1} display="inline" className="truncated">
          {phoneNumber}
        </Box>
      </Tooltip>

      {verified && (
        <Tooltip title="Phone number verified">
          <Box component="span" mr={1}>
            <Icon path={mdiCheckDecagram} size={0.8} />
          </Box>
        </Tooltip>
      )}
    </Box>
  ) : (
    <span>-</span>
  )
}

export const FixedPointCell = ({ cell }) => format.fixedPoint(cell.value || 0, 1)
export const IntegerCell = ({ cell }) => format.fixedPoint(cell.value || 0, 0)
export const CurrencyCell = ({ cell }) => format.money(cell.value || 0)
export const PreFormattedCell = ({ cell }) => <div style={{ width: cell.value.width }}>{cell.value.display}</div>
export const PersonNameCell = ({ cell }) => format.name(cell.value)
export const BoldPersonNameCell = props => <b>{PersonNameCell(props)}</b>

const cellTypeToComponentMap = {
  phone: PhoneNumberCell,
  email: EmailCell,
}

export const ContactCell = ({ cell }) => {
  const { value, verified, type } = cell.value

  const Component = cellTypeToComponentMap[type] || NullableTextCell

  return <Component cell={{ ...cell, value }} verified={verified} />
}

export * from './EditCell'
export * from './useOrgDateTimeCell'
