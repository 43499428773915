import HelpIcon from '@material-ui/icons/Help'
import CloseIcon from '@material-ui/icons/Close'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import { Modal } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/styles'

interface HelpModalProps {
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px',
    width: '660px',
    background: '#F8FAFF',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '25px',
    // TODO: this should be 960, when we'll figure out solution for ticket list display
    // @ts-ignore
    [theme.breakpoints.down(2960)]: {
      width: '400px',
      maxWidth: '100%',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '12px',
  },
  title: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    margin: 0,
  },
  close: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
  },
  knowledgeBase: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '20px 5px',
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  actionBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '315px',
    gap: '20px',
    justifyContent: 'center',
    alignSelf: 'stretch',
    padding: '20px 0',
    // @ts-ignore
    [theme.breakpoints.down(400)]: {
      width: 'auto',
    },
  },
  description: {
    margin: 0,
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'center',
  },
  knowledgeFrameContainer: {
    width: '294px',
    height: '132px',
    overflow: 'hidden',
    // TODO: this should be 960, when we'll figure out solution for ticket list display
    // @ts-ignore
    [theme.breakpoints.down(2960)]: {
      display: 'none',
    },
  },
  knowledgeFrame: {
    transform: 'scale(0.59) translate(-275px, -247px)',
    width: '724px',
    height: '380px',
  },
  customerSuccess: {
    display: 'flex',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    padding: '5px',
    flexWrap: 'wrap',
    justifyContent: 'center',
    rowGap: '20px',
  },
  customerSuccessAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '294px',
    '& > img': {
      width: '184px',
      height: '184px',
      borderRadius: '184px',
    },
  },
  icon: {
    // @ts-ignore
    color: theme.palette.primary.main,
  },
}))

export const HelpModal = ({ open, onClose }: HelpModalProps) => {
  const classes = useStyles()
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="help-modal-title" aria-describedby="help-modal-description">
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.titleContainer}>
            <HelpIcon className={classes.icon} /> <h2 className={classes.title}>Help</h2>
          </div>
          <div className={classes.close} onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className={classes.knowledgeBase}>
          <div className={classes.actionBlock}>
            <p className={classes.description}>Check out our knowledge base for walkthroughs and tutorials!</p>
            <ContainedButton onClick={() => window.open(process.env.REACT_APP_HELPDESK_URL)}>
              Knowledge Base
            </ContainedButton>
          </div>
          <div className={classes.knowledgeFrameContainer}>
            <iframe
              title="knowledge base"
              className={classes.knowledgeFrame}
              src={`${process.env.REACT_APP_HELPDESK_URL}#solutions-index-home`}
            />
          </div>
        </div>
        <div className={classes.customerSuccess}>
          <div className={classes.actionBlock}>
            <p className={classes.description}>
              Need to speak to someone? Our Customer Success team will handle any issues or concerns you have.
            </p>
            <ContainedButton onClick={() => window.open('mailto:help@civicchamps.com')}>
              Contact Customer Success
            </ContainedButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}
