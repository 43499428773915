import React, { useState } from 'react'
import { ConfirmDialog, RadioList, useStyles } from 'civic-champs-shared/core/confirm-dialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import cn from 'classnames'

export enum EditType {
  Single = 'single',
  Future = 'future',
  All = 'all',
}

export interface Props {
  showing: boolean
  complete: (value: any) => void
  cancel: () => void
  close: () => void
}

export default function EditRecurringEventConfirmDialog(props: Props) {
  const { showing, complete, close } = props
  const classes = useStyles()

  const [editType, setEditType] = useState<EditType>(EditType.All)

  const handleEditTypeChange = (e: any) => {
    setEditType(e.target.value)
  }

  const handleSubmit = () => {
    complete({ editType })
  }

  return (
    <ConfirmDialog
      showing={showing}
      close={close}
      complete={handleSubmit}
      title="You are editing an event that is part of a series"
      text="You are editing an event that was created as part of a series of events. For a recurring event series, you may apply the edits made to the following:"
      cancelText="Do not Edit"
      confirmText="Continue with Edit"
      noTextGap
    >
      <RadioList
        onChange={handleEditTypeChange}
        value={editType}
        name="edit-type"
        options={[
          {
            value: EditType.Single,
            label: 'Only edit this event',
          },
          {
            value: EditType.Future,
            label: 'Edit this and all subsequent events in this series',
          },
          {
            value: EditType.All,
            label: 'Edit all events in this series',
          },
        ]}
      />
      <DialogContentText classes={{ root: cn(classes.text, classes.noGap) }}>
        To maintain accurate records, edits will never apply to concluded events.
      </DialogContentText>
    </ConfirmDialog>
  )
}
