import { useCreateApiNotification, useFetchRefactored } from '../../civic-champs-shared/api/hooks'
import { useCallback } from 'react'

export const useProcessGroupApplication = () => {
  const [request] = useFetchRefactored()
  const createNotification = useCreateApiNotification({ isNew: true, isMultiline: true })

  return useCallback(
    async ({
      groupId,
      personIds,
      approve,
      comment,
    }: {
      groupId: number
      personIds: number[] | number
      approve: boolean
      comment?: string
    }) => {
      const notification = createNotification((approve ? 'Approving' : 'Declining') + ' application')
      try {
        const result = await request({
          method: 'post',
          url: `/groups/${groupId}/membership_actions`,
          config: {
            body: {
              type: approve ? 'approved' : 'declined',
              personIds: Array.isArray(personIds) ? personIds : [personIds],
              comment: comment,
            },
          },
        })
        notification.onSuccess(approve ? `Application Approved` : `Application Declined`)
        return result
      } catch (e) {
        notification.onError(`Failed to ${approve ? 'Approve' : 'Decline'} Application`, e)
        throw e
      }
    },
    [createNotification, request],
  )
}
