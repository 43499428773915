import moment from 'moment'
import dayjs from '../dayjs'

export enum RECURRING_TYPE {
  ANNUALLY = 0,
  MONTHLY_BY_WEEKDAY_OF_MONTH = 1,
  WEEKLY = 2,
  EVERY_WEEKDAY = 3,
  CUSTOM_EVENTS = 4,
  NEVER = 5,
  EVERYDAY = 6,
  MONTHLY_BY_DAY_OF_MONTH = 7,
}

export const DEFAULT_RECURRING_TYPE = {
  label: 'Never',
  value: RECURRING_TYPE.NEVER,
}

const weeks: { [key: number]: string } = {
  1: 'First',
  2: 'Second',
  3: 'Third',
  4: 'Fourth',
  5: 'Fifth',
}

export const getWeekdayNumberInMonth = (startsAt: string | Date) => {
  let counter = 1
  const eventDate = dayjs(startsAt)
  let monthStart = dayjs(startsAt).startOf('month')
  while (monthStart.date() < eventDate.date()) {
    if (dayjs(monthStart).day() === dayjs(startsAt).day()) {
      counter++
    }
    monthStart = monthStart.add(1, 'day')
  }
  return counter
}

export type RECURRING_TYPE_OPTION = {
  label: string
  value: RECURRING_TYPE
}

export const RECURRING_TYPE_OPTIONS = (startsAt: string | Date, newUI: boolean = false): RECURRING_TYPE_OPTION[] => {
  const weekNumber = getWeekdayNumberInMonth(startsAt)

  return [
    {
      label: `Annually on ${moment(startsAt).format('MMMM D')}`,
      value: RECURRING_TYPE.ANNUALLY,
    },
    {
      label: `Monthly on the ${moment(startsAt).format('Do')}`,
      value: RECURRING_TYPE.MONTHLY_BY_DAY_OF_MONTH,
    },
    {
      label: `Monthly on ${weeks[weekNumber]} ${moment(startsAt).format('dddd')}`,
      value: RECURRING_TYPE.MONTHLY_BY_WEEKDAY_OF_MONTH,
    },
    {
      label: `Weekly on ${moment(startsAt).format('dddd')}`,
      value: RECURRING_TYPE.WEEKLY,
    },
    {
      label: 'Every weekday',
      value: RECURRING_TYPE.EVERY_WEEKDAY,
    },
    {
      label: 'Everyday',
      value: RECURRING_TYPE.EVERYDAY,
    },
    {
      label: 'Custom events',
      value: RECURRING_TYPE.CUSTOM_EVENTS,
    },
    {
      label: newUI ? 'One Day Event' : 'Never',
      value: RECURRING_TYPE.NEVER,
    },
  ]
}
