import moment from 'moment'
import { Field, FieldArray, Form, Formik } from 'formik'
import { FormModalScreen } from 'components/FormModal'
import {
  StyledKeyboardDatePicker,
  StyledKeyboardTimePicker,
} from 'civic-champs-shared/formik/components/StyledFormikDateTimePickers'
import React, { useEffect } from 'react'
import { useAddEditEventStyles } from 'Event/hooks/useAddEditEventStyles'
import AdvancedAutocomplete from 'civic-champs-shared/core/AdvancedAutocomplete'
import { OpportunityTemplate } from 'volunteering/opportunities/interfaces'
import useGetOpportunityTemplates from 'volunteering/opportunities/hooks/useGetOpportunityTemplates'
import { RECURRING_TYPE } from 'civic-champs-shared/core/utils/constants/RECURRING_TYPE_OPTIONS'
import { filter } from 'lodash'
import StyledInput from 'civic-champs-shared/core/StyledInput'
import DeleteIcon from '@material-ui/icons/Delete'
import { Role } from 'volunteer-role/types'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { InlineAddButton } from 'civic-champs-shared/core/InlineAddButton'
import { FormRole } from 'Event/helpers/add-edit-helpers'
import { useFeatureEnabled } from 'core/feature/hooks'
import cn from 'classnames'
import RadioGroupFormField from 'components/RadioGroupFormField'
import { LegacySchedulableBlock } from './add-edit-event-dates/LegacySchedulableBlock'
import {
  MONTHLY_RECURRING_TYPE,
  SIMPLE_RECURRING_TYPE,
} from '../../civic-champs-shared/core/utils/constants/SIMPLE_RECURRING_TYPE_OPTIONS'
import { SchedulableBlock } from './add-edit-event-dates/SchedulableBlock'
import {
  editEventRolesPayloadSchema,
  eventDatePayloadSchema,
  simpleEventDatePayloadSchema,
} from '../validation/eventDatePayloadSchema'
import { ObjectSchema } from 'yup'
import { SchedulableEditBlock } from './add-edit-event-dates/SchedulableEditBlock'

export interface EventDateScreenValues {
  startDate: Date | null
  dates: Date[]
  endDate: Date | null
  startTime: Date
  endTime: Date
  opportunityTemplate: OpportunityTemplate | null
  isSchedulable: boolean
  recurringType?: RECURRING_TYPE
  simpleRecurringType: SIMPLE_RECURRING_TYPE
  roles: FormRole[]
  useTemplate: boolean
  monthlyRecurringType: MONTHLY_RECURRING_TYPE
}

export interface EventDateScreenProps {
  values: EventDateScreenValues
  onSubmit: (values: EventDateScreenValues, asDraft?: boolean) => void
  onBack: () => void
  isNewEvent: boolean
  isDraftable: boolean
  roles: Role[]
  addRole: () => Promise<Role>
}

export const EventDateAndRoleScreen = ({
  values,
  onSubmit,
  onBack,
  isNewEvent,
  roles: allRoles,
  addRole,
  isDraftable,
}: EventDateScreenProps) => {
  const isCalendarEventSwitchEnabled = useFeatureEnabled('NewAddEventUICalendarEvent')
  const isAllowEditShiftsWithRegistrationsEnabled = useFeatureEnabled('ChangeTimeForShiftsWithRegistrations')
  const isSimplifyRecurringEventRulesEnabled = useFeatureEnabled('SimplifyRecurringEventRules')
  const isMaintainEventSequencesAcrossEdits = useFeatureEnabled('MaintainEventSequencesAcrossEdits')
  const isConcluded = !isNewEvent && moment(values.startDate as Date).isBefore(new Date(), 'day')
  const classes = useAddEditEventStyles()
  const [asDraft, setAsDraft] = React.useState(false)

  const [getOpportunityTemplates, { result: opportunityTemplates }] =
    useGetOpportunityTemplates(isCalendarEventSwitchEnabled)
  useEffect(() => {
    if (isNewEvent) {
      getOpportunityTemplates(true, true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  let validationSchema: ObjectSchema
  if (isNewEvent) {
    validationSchema = isSimplifyRecurringEventRulesEnabled ? simpleEventDatePayloadSchema : eventDatePayloadSchema
  } else {
    validationSchema = editEventRolesPayloadSchema
  }

  return (
    <Formik
      initialValues={values}
      validationSchema={validationSchema}
      onSubmit={values => onSubmit(values, asDraft)}
      validateOnChange
      isInitialValid={!isNewEvent}
    >
      {({ submitForm, isSubmitting, values, setFieldValue, errors }) => {
        const selectedRoles = new Set([...values.roles.map(({ roleId }) => roleId)])
        const handleAdd = async (index: number) => {
          const role = await addRole()
          setFieldValue(
            'roles',
            (values.roles as any).toSpliced(index, 1, { roleId: role.id, quantity: values.roles[index].quantity }),
          )
        }
        return (
          <Form>
            <FormModalScreen
              onBack={onBack}
              onNext={submitForm}
              onDraft={
                values.isSchedulable && isDraftable
                  ? () => {
                      setAsDraft(true)
                      submitForm()
                    }
                  : undefined
              }
              nextText="Next"
              draftText={values.isSchedulable && isDraftable ? 'Finish Later' : undefined}
              backText="Cancel"
              disabled={isSubmitting}
            >
              {isNewEvent && opportunityTemplates.length !== 0 && (
                <div className={cn(classes.section, classes.subSection)}>
                  <span className={classes.sectionTitle}>Event Details:</span>
                  <RadioGroupFormField
                    valueIsBoolean
                    name="useTemplate"
                    disabled={isSubmitting}
                    options={[
                      {
                        label: 'Create event from a Template',
                        value: true,
                      },
                    ]}
                  />
                  {values.useTemplate && (
                    <div className={classes.subDropdown}>
                      <AdvancedAutocomplete<OpportunityTemplate>
                        label={'Event Template'}
                        options={opportunityTemplates}
                        placeholder={'Select an Event Template'}
                        value={values.opportunityTemplate}
                        getOptionInfo={template => ({
                          left: template.name,
                          id: template.id,
                        })}
                        onChange={opportunityTemplate => setFieldValue('opportunityTemplate', opportunityTemplate)}
                      />
                    </div>
                  )}
                  <RadioGroupFormField
                    valueIsBoolean
                    name="useTemplate"
                    disabled={isSubmitting}
                    unGap={!values.useTemplate}
                    options={[
                      {
                        label: 'Create event from Scratch',
                        value: false,
                      },
                    ]}
                  />
                </div>
              )}

              {isNewEvent && (
                <div className={classes.section}>
                  {!values.isSchedulable ||
                    ((isNewEvent || isAllowEditShiftsWithRegistrationsEnabled) && isCalendarEventSwitchEnabled && (
                      <span className={classes.sectionTitle}>Event Time:</span>
                    ))}
                  {(isNewEvent || isAllowEditShiftsWithRegistrationsEnabled) &&
                    isCalendarEventSwitchEnabled &&
                    (!isSimplifyRecurringEventRulesEnabled ? (
                      <LegacySchedulableBlock values={values} setFieldValue={setFieldValue} />
                    ) : (
                      <SchedulableBlock values={values} setFieldValue={setFieldValue} errors={errors} />
                    ))}
                  {!values.isSchedulable && (
                    <>
                      <div className={classes.dateTimePickers}>
                        <div className={classes.dateTimePicker}>
                          <Field
                            inputVariant="outlined"
                            label="Start Date *"
                            higher
                            component={StyledKeyboardDatePicker}
                            name="startDate"
                          />
                        </div>

                        <div className={classes.dateTimePicker}>
                          <Field
                            higher
                            inputVariant="outlined"
                            label="End Date *"
                            component={StyledKeyboardDatePicker}
                            name="endDate"
                            minDate={moment(values.startDate || undefined)
                              .startOf('day')
                              .toDate()}
                            minDateMessage={'The end date cannot occur before the start date.'}
                          />
                        </div>
                      </div>

                      <div className={classes.dateTimePickers}>
                        <div className={classes.dateTimePicker}>
                          <Field
                            inputVariant="outlined"
                            label="Start Time *"
                            higher
                            component={StyledKeyboardTimePicker}
                            name="startTime"
                          />
                        </div>

                        <div className={classes.dateTimePicker}>
                          <Field
                            higher
                            inputVariant="outlined"
                            label="End Time *"
                            component={StyledKeyboardTimePicker}
                            name="endTime"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              {values.isSchedulable && (
                <>
                  <div className={classes.section}>
                    <span className={classes.sectionTitle}>Roles:</span>
                    <FieldArray name="roles">
                      {({ remove, push }) => (
                        <div className={classes.section}>
                          {values.roles.map((role, index) => (
                            <div className={classes.role} key={index}>
                              <DeleteIcon
                                className={classes.roleDelete}
                                onClick={() => {
                                  remove(index)
                                }}
                              />
                              <div className={classes.roleQuantity}>
                                <StyledInput
                                  value={role.quantity ? role.quantity.toString() : ''}
                                  label={'Max'}
                                  placeholder={'Number'}
                                  onChange={quantity => {
                                    if (/^\d*$/.test(quantity)) {
                                      setFieldValue(`roles[${index}].quantity`, quantity ? parseInt(quantity) : null)
                                    }
                                  }}
                                  error={(errors.roles?.[index] as { quantity: string } | undefined)?.quantity}
                                />
                              </div>
                              <div className={classes.roleSelector}>
                                <AdvancedAutocomplete<Role>
                                  label={'Role'}
                                  placeholder={'Select a Role'}
                                  options={filter(allRoles, ({ id }) => !selectedRoles.has(id) || id === role.roleId)}
                                  value={allRoles.find(({ id }) => id === role.roleId)}
                                  getOptionInfo={option => ({
                                    left: option.name,
                                    id: option.id,
                                  })}
                                  onAdd={() => handleAdd(index)}
                                  addNewText={'Add a new role'}
                                  addNewIcon={<AddCircleIcon className={classes.addIcon} />}
                                  onChange={option =>
                                    setFieldValue(`roles[${index}].roleId`, option ? (option as Role).id : null)
                                  }
                                  error={(errors.roles?.[index] as { roleId: string } | undefined)?.roleId}
                                />
                              </div>
                            </div>
                          ))}
                          <InlineAddButton onClick={() => push({ id: null, quantity: null })} text="Add Role" />
                        </div>
                      )}
                    </FieldArray>
                  </div>
                  {isMaintainEventSequencesAcrossEdits &&
                    isSimplifyRecurringEventRulesEnabled &&
                    !isNewEvent &&
                    !isConcluded && (
                      <div className={classes.section}>
                        <span className={classes.sectionTitle}>Scheduling:</span>
                        <SchedulableEditBlock />
                      </div>
                    )}
                </>
              )}
            </FormModalScreen>
          </Form>
        )
      }}
    </Formik>
  )
}
