import * as React from 'react'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

import './index.scss'
import { useFormatDates } from '../../helpers/toFormatFullDateToISO'
import StyledInput from '../../../civic-champs-shared/core/StyledInput'
import EventIcon from '@material-ui/icons/Event'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

export interface DatePickerMultiSelectProps {
  onChange: (date: string) => void
  minDate?: string
  isDisabled?: boolean
  dates: Date[]
  datesOnScreen: number
  label?: string
  placeholder?: string
  error?: string
  belowField?: boolean
}

const useStyles = makeStyles({
  adornment: {
    // to be aligned with regular mui datepicker icon placement
    right: '25px',
  },
})

const DatePickerMultiSelect = (props: DatePickerMultiSelectProps) => {
  const { label, placeholder, dates, onChange, minDate, isDisabled, datesOnScreen, error } = props
  const classes = useStyles()

  const { timeZone } = useFormatDates()

  const [showDatepicker, handleShowDatepicker] = useState(false)

  const [datepickerRef, setRef] = useState(null)

  const handleClickOutside = (event: any) => {
    const timePickerNode = ReactDOM.findDOMNode(datepickerRef)
    const parentNode = timePickerNode?.parentNode?.parentNode
    const condition = parentNode && !parentNode.contains(event.target) && document.getRootNode().contains(event.target)

    if (condition && showDatepicker) {
      handleCloseDatePicker()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  })

  const handleClick = () => {
    handleShowDatepicker(true)
  }

  const handleChangeRef = (ref: any) => setRef(ref)

  const value =
    dates
      .map(date => moment(date).tz(timeZone, true).format('MM/DD/YYYY'))
      .slice(0, datesOnScreen)
      .join(', ') + (dates.length > datesOnScreen ? '...' : '')

  const handleCloseDatePicker = () => {
    handleShowDatepicker(false)
  }

  const handleChange = (date: Date) => {
    const formattedDate = date && moment(date).format('MM/DD/YYYY')
    onChange(formattedDate)
  }

  return (
    <React.Fragment>
      <div className="custom-datepicker-multi-select-new">
        <StyledInput
          label={label}
          placeholder={placeholder}
          ref={handleChangeRef}
          onChange={() => {}}
          onFocus={handleClick}
          value={value}
          endAdornment={<EventIcon />}
          endAdornmentClassName={classes.adornment}
          error={error}
        />
        {showDatepicker && (
          <div className={cn('custom-datepicker-multi-select-new__datepicker', { under: props.belowField })}>
            <DatePicker
              inline
              disabled={isDisabled}
              dateFormat="MM/dd/yyyy"
              minDate={minDate ? new Date(minDate) : undefined}
              isClearable
              showYearDropdown
              onChange={handleChange}
              highlightDates={dates}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default DatePickerMultiSelect
