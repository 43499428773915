import { OpportunityPayload, OpportunityVisibility } from 'Event/interfaces'
import {
  EventGroup,
  OpportunityOccurrencePersonGroup,
  OpportunityResponseWithRecurrenceInfo,
  OpportunityStatus,
  RecurrenceInfo,
  TimeshiftsBase,
} from 'Event/interfaces/interfaceCreateEditEvent'
import { Geofencing } from 'civic-champs-shared/core/location/utils'
import {
  OpportunityTemplate,
  OpportunityTemplateCredential,
  OpportunityTemplatePersonGroup,
  OpportunityTemplateSurvey,
} from 'volunteering/opportunities/interfaces'
import {
  getWeekdayNumberInMonth,
  RECURRING_TYPE,
} from 'civic-champs-shared/core/utils/constants/RECURRING_TYPE_OPTIONS'
import { isNull, omitBy } from 'lodash'
import { EventGeofencing, mapEventGeofencingToGeofencing, mapGeofencingToEventGeofencing } from 'utils/event'
import moment from 'moment-timezone'
import { JoinQuestionSetPayload, JoinSurveyPayload } from 'civic-champs-shared/question-sets/types'
import { Role } from 'volunteer-role/types'
import { Person } from '../../people/interface'
import { OrganizationLocationReporting } from '../../locations/types'
import {
  MONTHLY_RECURRING_TYPE,
  SIMPLE_RECURRING_TYPE,
} from '../../civic-champs-shared/core/utils/constants/SIMPLE_RECURRING_TYPE_OPTIONS'
import dayjs from '../../civic-champs-shared/core/utils/dayjs'
import last from 'lodash/last'
import first from 'lodash/first'
import { RRule, RRuleSet, rrulestr, Weekday } from 'rrule'
import isUndefined from 'lodash/isUndefined'

export interface FormRole {
  roleId: number | null
  quantity: number | null
  registrations: number
}

export interface FormReferenceItem {
  id: number
  name: string
}

export interface FormData {
  name: string
  description: string
  address: string
  city: string
  state: string
  zip: string
  visibility: OpportunityVisibility
  isTest: boolean
  locationDetails: string
  locationIsAddress: boolean
  startDate: Date | null
  endDate: Date | null
  startTime: Date
  endTime: Date
  dates: Date[]
  geofencing: Geofencing
  groups: EventGroup[]
  opportunityTemplate: OpportunityTemplate | null
  isSchedulable: boolean
  recurringType: RECURRING_TYPE
  roles: FormRole[]
  contactEmail: string
  contactName: string
  contactPhoneNumber: string
  instructions: string
  questionnaires: FormReferenceItem[]
  waivers: FormReferenceItem[]
  timeShiftId?: number
  timeShiftName?: string
  editMode?: string
  organizationLocationId: number | null
  useTemplate?: boolean
  pointOfContactPersons?: Person[]
  useCustomAddress: boolean
  simpleRecurringType: SIMPLE_RECURRING_TYPE
  monthlyRecurringType: MONTHLY_RECURRING_TYPE
  isRecurring?: boolean
  recurrencePattern?: string
}

interface RecurrencePatternProps {
  startDate: Date
  endDate: Date
  dates: Date[]
  simpleRecurringType: SIMPLE_RECURRING_TYPE
  monthlyRecurringType: MONTHLY_RECURRING_TYPE
}

export interface EventPayload {
  id?: string | number
  name: string
  startsAt: Date
  endsAt: Date | null
  recurring_type?: number
  recurrencePattern?: string | null
  is_recurring: boolean
  dates: string[]
  description: string
  instructions: string
  contact_name: string
  contact_email: string
  contact_phone_number: string
  address: string | null
  city: string | null
  state: string | null
  zip: string | null
  isTest: boolean
  geofencing: EventGeofencing
  questionSets?: (JoinQuestionSetPayload | JoinSurveyPayload)[] | null
  visibilityGroups?: EventGroup[]
  onboardingGroups?: EventGroup[]
  groups?: EventGroup[]
  requiredCredentials: {
    id: number
    title: string
  }[]
  visibility: OpportunityVisibility
  recurrenceInfo?: RecurrenceInfo
  occurrenceId?: number
  opportunityId?: number
  timeshifts: TimeshiftsBase[]
  locationDetails: string | null
  isSchedulable: true
  asDraft?: boolean
  editMode?: string
  organizationLocationId?: number | null
  pointOfContactPersonIds?: number[]
  moveToDate?: Date
}

export const getFormValuesFromOpportunityTemplate = ({
  opportunityTemplate,
  opportunityTemplateGroups,
  opportunityTemplateSurveys,
  opportunityTemplateCredentials,
  namedLocations,
}: {
  opportunityTemplate?: OpportunityTemplate | null
  opportunityTemplateGroups: OpportunityTemplatePersonGroup[]
  opportunityTemplateSurveys: OpportunityTemplateSurvey[]
  opportunityTemplateCredentials: OpportunityTemplateCredential[]
  namedLocations: OrganizationLocationReporting[]
}): Partial<FormData> => {
  if (!opportunityTemplate) return {}
  const {
    name,
    streetAddress: address,
    geofencing,
    city,
    state,
    zip,
    visibility,
    locationDetails,
    locationIsAddress,
    contactName,
    contactEmail,
    contactPhoneNumber,
    description,
    instructions,
    organizationLocationId,
    pointOfContactPersons,
  } = opportunityTemplate
  const values: Partial<FormData> = omitBy(
    {
      name,
      geofencing: geofencing ? mapEventGeofencingToGeofencing(geofencing) : null,
      address,
      city,
      state,
      zip,
      visibility,
      locationDetails,
      locationIsAddress,
      contactName,
      contactEmail,
      contactPhoneNumber,
      description,
      instructions,
      pointOfContactPersons,
      groups: opportunityTemplateGroups.map(({ group, associationType, approvedMembersOnly }) => ({
        groupId: group.id,
        name: group.name,
        associationType: associationType,
        approvedMembersOnly: approvedMembersOnly,
        closed: group.closed,
      })),
      questionnaires: opportunityTemplateSurveys.map(({ survey: { questionSetName, id } }) => ({
        name: questionSetName,
        id,
      })),
      waivers: opportunityTemplateCredentials.map(({ credential: { id, name } }) => ({ name, id })),
    },
    isNull,
  )
  if (!organizationLocationId && (locationIsAddress || locationDetails === null || locationDetails === '')) {
    values.useCustomAddress = false
  } else {
    values.useCustomAddress = !namedLocations.some(namedLocation => namedLocation.id === organizationLocationId)
  }
  values.organizationLocationId = organizationLocationId
  return values
}

export const mapToEventGroup = (
  eventGroup: OpportunityOccurrencePersonGroup | OpportunityTemplatePersonGroup,
): EventGroup => ({
  groupId: eventGroup.group.id,
  name: eventGroup.group.name,
  associationType: eventGroup.associationType,
  approvedMembersOnly: eventGroup.approvedMembersOnly,
  closed: eventGroup.group.closed,
})

export const mapFormDataToOpportunityPayload = (values: FormData): OpportunityPayload => {
  const {
    startDate,
    startTime,
    endDate,
    endTime,
    geofencing,
    groups,
    address,
    city,
    state,
    zip,
    locationDetails,
    locationIsAddress,
    opportunityTemplate,
    // event-specific, not used for opportunity
    recurringType,
    roles,
    contactEmail,
    contactName,
    contactPhoneNumber,
    instructions,
    questionnaires,
    waivers,
    timeShiftId,
    // end event-specific, not used for opportunity
    ...data
  } = values
  const start = moment(startDate as Date)
  const end = moment(endDate as Date)
  return {
    ...data,
    address: locationIsAddress ? address : null,
    city: locationIsAddress ? city : null,
    state: locationIsAddress ? state : null,
    zip: locationIsAddress ? zip : null,
    locationDetails: locationIsAddress ? null : locationDetails,
    startsAt: moment(startTime as Date)
      .year(start.year())
      .dayOfYear(start.dayOfYear())
      .toDate(),
    endsAt: moment(endTime as Date)
      .year(end.year())
      .dayOfYear(end.dayOfYear())
      .toDate(),
    geofencing: mapGeofencingToEventGeofencing(geofencing),
    groups,
    ...(opportunityTemplate ? { opportunityTemplateId: opportunityTemplate.id } : {}),
    isSchedulable: false,
    locationIsAddress,
  }
}

export const mapFormDataToEventPayload = (
  values: FormData,
  isQuestionnaireAsSurveyEnabled: boolean,
  roles: Role[],
  timeZone: string,
  asDraft?: boolean,
  isSimplifyRecurringEventRulesEnabled: boolean = false,
  startDateChanged: boolean = false,
): EventPayload => {
  const {
    name,
    description,
    visibility,
    startDate,
    startTime,
    endDate,
    endTime,
    geofencing,
    groups,
    address,
    city,
    state,
    zip,
    locationDetails,
    locationIsAddress,
    opportunityTemplate,
    recurringType,
    roles: formRoles,
    contactEmail,
    contactName,
    contactPhoneNumber,
    instructions,
    questionnaires,
    waivers,
    isTest,
    timeShiftId,
    timeShiftName,
    editMode,
    organizationLocationId,
    pointOfContactPersons,
    dates,
    simpleRecurringType,
    monthlyRecurringType,
    recurrencePattern,
    isRecurring,
  } = values
  let start: moment.Moment
  let end: moment.Moment
  if (isSimplifyRecurringEventRulesEnabled) {
    if (recurrencePattern) {
      const ruleDates = rrulestr(recurrencePattern).all()
      start = moment(first(ruleDates))
      end = moment(last(ruleDates))
    } else {
      start = moment(first(dates))
      end = simpleRecurringType === SIMPLE_RECURRING_TYPE.SELECTED_DATES ? moment(last(dates)) : moment(endDate as Date)
    }
  } else {
    start = moment(startDate as Date)
    end = moment(endDate as Date)
  }
  const startsAt = moment
    .tz(
      moment(startTime as Date)
        .year(start.year())
        .dayOfYear(start.dayOfYear())
        .format('YYYY-MM-DD HH:mm:ss'),
      timeZone,
    )
    .toDate()
  const endsAt = moment
    .tz(
      moment(endTime as Date)
        .year(end.year())
        .dayOfYear(end.dayOfYear())
        .format('YYYY-MM-DD HH:mm:ss'),
      timeZone,
    )
    .toDate()

  const result: EventPayload = {
    is_recurring: false,
    name,
    startsAt,
    endsAt,
    description,
    isTest,
    instructions,
    contact_name: contactName,
    contact_email: contactEmail,
    contact_phone_number: contactPhoneNumber,
    address: locationIsAddress ? address : null,
    city: locationIsAddress ? city : null,
    state: locationIsAddress ? state : null,
    zip: locationIsAddress ? zip : null,
    locationDetails: locationIsAddress ? null : locationDetails,
    geofencing: mapGeofencingToEventGeofencing(geofencing),
    dates: [],
    visibility,
    questionSets: questionnaires.map(({ id, name }) => ({
      [isQuestionnaireAsSurveyEnabled ? 'surveyId' : 'questionSetId']: id,
      name,
      required: true,
    })) as unknown as (JoinQuestionSetPayload | JoinSurveyPayload)[],
    requiredCredentials: waivers.map(({ id, name }) => ({ id, title: name, requiresReview: false })),
    groups: groups.filter(group => !group.lockedTag),
    timeshifts: [
      {
        ...(timeShiftId ? { id: timeShiftId } : {}),
        time_start: moment(startTime).format('h:mm A'),
        time_end: moment(endTime).format('h:mm A'),
        name: timeShiftName || 'Default',
        roles: formRoles
          .filter(({ roleId }) => roleId)
          .map(({ roleId, quantity }) => ({
            id: roleId,
            available: quantity,
            name: roles.find(({ id }) => id === roleId)!.name,
          })),
      },
    ],
    ...(opportunityTemplate ? { opportunityTemplateId: opportunityTemplate.id } : {}),
    isSchedulable: true,
    asDraft,
    editMode,
    organizationLocationId,
    pointOfContactPersonIds: pointOfContactPersons?.map(({ id }) => id),
    ...(startDateChanged ? { moveToDate: startDate as Date } : {}),
  }

  if (isSimplifyRecurringEventRulesEnabled) {
    result.is_recurring = !isUndefined(isRecurring)
      ? isRecurring
      : simpleRecurringType !== SIMPLE_RECURRING_TYPE.SELECTED_DATES || dates.length > 1
    result.recurrencePattern = !isUndefined(recurrencePattern)
      ? recurrencePattern
      : getRecurrencePattern({
          startDate: startsAt,
          endDate: endsAt,
          dates,
          simpleRecurringType,
          monthlyRecurringType,
        })
  } else {
    result.recurring_type = recurringType
    result.is_recurring = recurringType !== RECURRING_TYPE.NEVER
    result.recurrenceInfo = {
      type: recurringType,
      startDate: startsAt,
      endDate: endsAt,
      timeZone,
    }
  }

  return result
}

export function getRecurrencePattern({
  startDate,
  endDate,
  dates,
  simpleRecurringType,
  monthlyRecurringType,
}: RecurrencePatternProps): string {
  switch (simpleRecurringType) {
    case SIMPLE_RECURRING_TYPE.WEEKLY:
      return new RRule({
        freq: RRule.WEEKLY,
        byweekday: dates.map(date => RRule[dayjs(date).format('dd').toUpperCase() as keyof typeof RRule]) as Weekday[],
        dtstart: startDate,
        until: endDate,
      }).toString()
    case SIMPLE_RECURRING_TYPE.MONTHLY:
      if (monthlyRecurringType === MONTHLY_RECURRING_TYPE.RESPECT_DATE) {
        return new RRule({
          freq: RRule.MONTHLY,
          bymonthday: dates.map(date => date.getDate()),
          dtstart: startDate,
          until: endDate,
        }).toString()
      } else {
        const weekdays = new Map<string, number[]>()
        for (const date of dates) {
          const weekday = dayjs(date).format('dd').toUpperCase()
          const weekdayNumber = getWeekdayNumberInMonth(date)
          if (!weekdays.has(weekday)) weekdays.set(weekday, [])
          weekdays.get(weekday)!.push(weekdayNumber)
        }
        const ruleSet = new RRuleSet()
        ;[...weekdays.entries()].forEach(([weekday, positions]) => {
          ruleSet.rrule(
            new RRule({
              freq: RRule.MONTHLY,
              byweekday: [RRule[weekday as keyof typeof RRule] as Weekday],
              bysetpos: positions,
              dtstart: startDate,
              until: endDate,
            }),
          )
        })
        return ruleSet.toString()
      }
    default:
      const dateObject = dayjs(startDate)
      const ruleSet = new RRuleSet()
      dates.forEach(date =>
        ruleSet.rdate(dateObject.year(date.getFullYear()).month(date.getMonth()).date(date.getDate()).toDate()),
      )
      return ruleSet.toString()
  }
}
export const getInitialEditMode = (event: OpportunityResponseWithRecurrenceInfo) => {
  return event.status === OpportunityStatus.Draft ? 'all' : 'single'
}
