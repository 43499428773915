import React, { useCallback, useEffect, useRef, useState } from 'react'
import Loader from 'Event/components/loader'
import { useExpansionPanelSummaryStyle } from 'Event/hooks/useExpansionPanelSummaryStyle'
import { EventExpansionPanelSummary } from 'Event/components/EventExpansionPanelSummary'
import { EventExpansionPanel } from 'Event/components/EventExpansionPanel'
import { EventExpansionPanelDetails } from 'Event/components/EventExpansionPanelDetails'
import useGetIntegrationKey, { emptyValue } from 'integrations/hooks/useGetIntegrationKey'
import { IntegrationKey, IntegrationProvider } from 'integrations/types'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { SwitchToggle } from 'civic-champs-shared/formik/components'
import { makeStyles } from '@material-ui/styles'
import { ContainedButton, OutlinedButton } from 'civic-champs-shared'
import useDeleteIntegrationKeyDialog from 'integrations/hooks/useDeleteIntegrationKeyDialog'
import useSaveIntegrationKey from 'integrations/hooks/useSaveIntegrationKey'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    gap: '16px',
    marginTop: '16px',
    flexWrap: 'wrap',
  },
  input: {
    width: '800px',
    maxWidth: '100%',
  },
})

export function Fastn({ scrollTop }: { scrollTop: number }) {
  const [expanded, setExpanded] = useState(true)
  const [loading, setLoading] = useState(true)
  const [integrationKey, setIntegrationKey] = useState<IntegrationKey>({ ...emptyValue, enabled: false })
  const summaryRef = useRef<HTMLDivElement>(null)
  const detailsRef = useRef<HTMLDivElement>(null)
  const style = useExpansionPanelSummaryStyle({
    expanded,
    scrollTop,
    summaryRef,
    detailsRef,
  })
  const classes = useStyles()

  const fetchIntegrationKey = useGetIntegrationKey(false)
  const refresh = useCallback(async () => {
    setLoading(true)
    setIntegrationKey(await fetchIntegrationKey(IntegrationProvider.FASTN))
    setLoading(false)
  }, [fetchIntegrationKey])
  useEffect(() => {
    refresh()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const saveIntegrationKey = useSaveIntegrationKey()
  const onSubmit = useCallback(
    async ({ enabled }: { enabled: boolean }) => {
      // token is server-filled
      await saveIntegrationKey({ token: 'token', enabled, provider: IntegrationProvider.FASTN })
      await refresh()
    },
    [refresh, saveIntegrationKey],
  )

  const showDeleteDialog = useDeleteIntegrationKeyDialog({ onSuccess: refresh })
  const onDelete = useCallback(() => {
    showDeleteDialog(IntegrationProvider.FASTN)
  }, [showDeleteDialog])

  if (loading) {
    return <Loader />
  }

  return (
    <EventExpansionPanel onChange={(_, expanded) => setExpanded(expanded)}>
      <EventExpansionPanelSummary style={style} ref={summaryRef} name="webhooks">
        <h1>Fastn</h1>
      </EventExpansionPanelSummary>
      <EventExpansionPanelDetails ref={detailsRef}>
        <Formik onSubmit={onSubmit} initialValues={integrationKey}>
          {({ isSubmitting, submitForm, values }) => (
            <Form>
              <div className={classes.container}>
                {values.token && (
                  <div className={classes.input}>
                    <Field
                      variant="outlined"
                      fullWidth
                      component={TextField}
                      name="token"
                      placeholder="Integration Id"
                      label="Integration Id"
                      disabled={true}
                    />
                  </div>
                )}
                <Field component={SwitchToggle} name="enabled" label="Enabled" />
              </div>
              <div className={classes.container}>
                <OutlinedButton disabled={!integrationKey.id} onClick={onDelete}>
                  Delete
                </OutlinedButton>
                <ContainedButton disabled={isSubmitting} isLoading={isSubmitting} onClick={submitForm}>
                  Save
                </ContainedButton>
              </div>
            </Form>
          )}
        </Formik>
      </EventExpansionPanelDetails>
    </EventExpansionPanel>
  )
}
