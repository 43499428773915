import React, { useCallback } from 'react'
import { RadioGroupProps } from 'formik-material-ui/dist/RadioGroup'
import { RadioGroup as MuiRadioGroup } from '@material-ui/core'
import isString from 'lodash/isString'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

const useStyles = makeStyles(() => ({
  unGap: {
    marginTop: '-10px',
  },
}))

export const RadioGroup = ({
  field: { name, value },
  form,
  meta,
  valueIsBoolean,
  unGap,
  ...props
}: RadioGroupProps & { valueIsBoolean?: boolean; unGap?: boolean }) => {
  const classes = useStyles()
  const stringValue = isString(value) ? value : value.toString()
  const setFieldValue = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      return form.setFieldValue(name, valueIsBoolean ? value === 'true' : value)
    },
    [form, name, valueIsBoolean],
  )
  return (
    <MuiRadioGroup
      classes={{ root: cn({ [classes.unGap]: unGap }) }}
      value={stringValue}
      onChange={setFieldValue}
      {...props}
    />
  )
}
