import { Field, FormikErrors } from 'formik'
import {
  StyledKeyboardDatePicker,
  StyledKeyboardTimePicker,
} from '../../../civic-champs-shared/formik/components/StyledFormikDateTimePickers'
import AdvancedAutocomplete from '../../../civic-champs-shared/core/AdvancedAutocomplete'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useAddEditEventStyles } from '../../hooks/useAddEditEventStyles'
import { EventDateScreenValues } from '../EventDateAndRoleScreen'
import {
  MONTHLY_RECURRING_TYPE,
  SIMPLE_RECURRING_TYPE,
} from '../../../civic-champs-shared/core/utils/constants/SIMPLE_RECURRING_TYPE_OPTIONS'
import DatePickerMultiSelect from '../date-picker-multi-select-new'
import dayjs from '../../../civic-champs-shared/core/utils/dayjs'
import cn from 'classnames'
import RadioGroupFormField from '../../../components/RadioGroupFormField'
import first from 'lodash/first'
import last from 'lodash/last'

interface Props {
  values: EventDateScreenValues
  setFieldValue: (field: keyof EventDateScreenValues, value: any, shouldValidate?: boolean) => void
  errors: FormikErrors<EventDateScreenValues>
}

export function SchedulableBlock({ values, setFieldValue, errors }: Props) {
  const classes = useAddEditEventStyles()

  const handleChangeMultiSelectDates = (date: string) => {
    const dateObject = dayjs(date, 'MM/DD/YYYY')
    const dates = (values.dates || []).map(v => dayjs(v))
    const index = dates.findIndex(v => v.isSame(dateObject, 'day'))
    if (index !== -1) {
      setFieldValue(
        'dates',
        dates.filter((v, i) => i !== index).map(v => v.toDate()),
      )
    } else {
      setFieldValue(
        'dates',
        [...dates, dateObject].sort((a, b) => a.diff(b, 'day')).map(v => v.toDate()),
      )
    }
  }

  const availableOptions = Object.values(SIMPLE_RECURRING_TYPE).filter(option => {
    if (option === SIMPLE_RECURRING_TYPE.SELECTED_DATES || values.dates.length <= 1) {
      return true
    }
    const firstDate = dayjs(first(values.dates))
    const lastDate = dayjs(last(values.dates))
    const unit = option === SIMPLE_RECURRING_TYPE.WEEKLY ? 'week' : 'month'
    return firstDate.isSame(lastDate, unit)
  })

  const getOptionDisabled = (option: SIMPLE_RECURRING_TYPE) => !availableOptions.includes(option)

  useEffect(() => {
    if (!availableOptions.includes(values.simpleRecurringType)) {
      setFieldValue('simpleRecurringType', SIMPLE_RECURRING_TYPE.SELECTED_DATES)
    }
  }, [availableOptions, setFieldValue, values.dates, values.simpleRecurringType])

  return (
    <>
      <div className={classes.dateTimePickers}>
        <div className={classes.dateTimePicker}>
          <Field
            inputVariant="outlined"
            label="Start Time *"
            higher
            component={StyledKeyboardTimePicker}
            name="startTime"
          />
        </div>

        <div className={classes.dateTimePicker}>
          <Field
            higher
            inputVariant="outlined"
            label="End Time *"
            component={StyledKeyboardTimePicker}
            name="endTime"
          />
        </div>
      </div>

      <span className={classes.sectionTitle}>Scheduling:</span>
      <div className={classes.dateTimePickers}>
        <DatePickerMultiSelect
          label="Event Date *"
          placeholder="Select Event Dates"
          datesOnScreen={4}
          dates={values.dates as Date[]}
          onChange={handleChangeMultiSelectDates}
          error={errors.dates as string}
        />
      </div>
      <div className={classes.dateTimePickers}>
        <AdvancedAutocomplete<SIMPLE_RECURRING_TYPE>
          label={'Recurrence'}
          options={Object.values(SIMPLE_RECURRING_TYPE)}
          disabled={!values.dates.length}
          value={values.simpleRecurringType}
          getOptionInfo={option => ({
            left: option,
            id: option,
          })}
          disableOptionsAutoFiltering
          disableInputFilter
          clearable={false}
          getOptionDisabled={getOptionDisabled}
          onChange={option => setFieldValue('simpleRecurringType', option as SIMPLE_RECURRING_TYPE)}
        />
      </div>
      {values.simpleRecurringType === SIMPLE_RECURRING_TYPE.MONTHLY && (
        <div className={cn(classes.section, classes.subSection)}>
          <RadioGroupFormField
            name="monthlyRecurringType"
            options={[
              {
                label: MONTHLY_RECURRING_TYPE.RESPECT_DATE,
                value: MONTHLY_RECURRING_TYPE.RESPECT_DATE,
              },
              {
                label: MONTHLY_RECURRING_TYPE.RESPECT_DAY_OF_WEEK,
                value: MONTHLY_RECURRING_TYPE.RESPECT_DAY_OF_WEEK,
              },
            ]}
          />
        </div>
      )}
      <div className={classes.dateTimePickers}>
        {values.simpleRecurringType !== SIMPLE_RECURRING_TYPE.SELECTED_DATES && (
          <Field
            higher
            inputVariant="outlined"
            label="End Date *"
            component={StyledKeyboardDatePicker}
            name="endDate"
            minDate={moment(values.startDate || undefined)
              .startOf('day')
              .toDate()}
            minDateMessage={'The end date cannot occur before the start date.'}
          />
        )}
      </div>
    </>
  )
}
