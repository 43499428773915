import React, { useMemo } from 'react'
import { RecurrenceInfo } from 'Event/interfaces/interfaceCreateEditEvent'
import moment from 'moment'
import useChangeEventScheduling from '../../../../hooks/useChangeEventScheduling'
import { Field, Form, Formik } from 'formik'
import { StyledKeyboardDatePicker } from '../../../../../civic-champs-shared/formik/components/StyledFormikDateTimePickers'
import { FormModal, FormModalScreen } from '../../../../../components/FormModal'
import { useAddEditEventStyles } from '../../../../hooks/useAddEditEventStyles'
import { RRuleSet, rrulestr } from 'rrule'
import RadioGroupFormField from '../../../../../components/RadioGroupFormField'
import DatePickerMultiSelect from '../../../../components/date-picker-multi-select-new'
import dayjs from '../../../../../civic-champs-shared/core/utils/dayjs'
import cn from 'classnames'
import { useHistory } from 'react-router'

interface Props {
  complete: () => void
  close: () => void
  cancel: () => void
  showing: boolean
  recurrenceInfo: RecurrenceInfo
  encodedOccurrence: string | number
}

export const ChangeSchedulingModal = (props: Props) => {
  const { close, complete, showing, recurrenceInfo, encodedOccurrence } = props
  const { endDate, startDate, pattern } = recurrenceInfo
  const startDateDisabled = useMemo(() => moment(startDate).isBefore(new Date(), 'day'), [startDate])
  const dates = rrulestr(pattern as string).all()
  const changeEventScheduling = useChangeEventScheduling()
  const history = useHistory()

  const classes = useAddEditEventStyles()
  const handleSubmit = async (values: { startDate: Date; endDate: Date; dates: Date[]; changePattern: boolean }) => {
    const payload = values.changePattern
      ? {
          recurrencePattern: values.dates
            .reduce((rset, date) => {
              rset.rdate(date)
              return rset
            }, new RRuleSet())
            .toString(),
        }
      : { startDate: values.startDate.toISOString(), endDate: values.endDate.toISOString() }
    await changeEventScheduling(encodedOccurrence as string, payload)
    complete()
    history.push('/list-of-events')
  }
  return (
    <FormModal id="event-scheduling-prompt" showing={showing} close={close} title="Edit Event Scheduling">
      <Formik
        initialValues={{ startDate, endDate, dates, changePattern: false }}
        onSubmit={values => handleSubmit(values)}
        validateOnChange
      >
        {({ submitForm, isSubmitting, values, setFieldValue, errors }) => {
          const handleChangeMultiSelectDates = (date: string) => {
            const dateObject = dayjs(date, 'MM/DD/YYYY')
            if (dateObject.isBefore(new Date(), 'day')) {
              return
            }
            const dates = (values.dates || []).map(v => dayjs(v))
            const index = dates.findIndex(v => v.isSame(dateObject, 'day'))
            if (index !== -1) {
              setFieldValue(
                'dates',
                dates.filter((v, i) => i !== index).map(v => v.toDate()),
              )
            } else {
              setFieldValue(
                'dates',
                [...dates, dateObject].sort((a, b) => a.diff(b, 'day')).map(v => v.toDate()),
              )
            }
          }

          return (
            <Form>
              <FormModalScreen
                onBack={close}
                onNext={submitForm}
                nextText="Save"
                backText="Cancel"
                disabled={isSubmitting}
              >
                <div className={cn(classes.section, classes.subSection)}>
                  <RadioGroupFormField
                    valueIsBoolean
                    name="changePattern"
                    disabled={isSubmitting}
                    options={[
                      {
                        label: 'Adjust Start and End Date',
                        value: false,
                      },
                    ]}
                  />
                  {!values.changePattern && (
                    <>
                      <div className={classes.dateTimePickers}>
                        <Field
                          inputVariant="outlined"
                          label="Start Date *"
                          higher
                          fullWidth
                          component={StyledKeyboardDatePicker}
                          name="startDate"
                          disabled={startDateDisabled || isSubmitting}
                        />
                      </div>

                      <div className={classes.dateTimePickers}>
                        <Field
                          higher
                          fullWidth
                          inputVariant="outlined"
                          label="End Date *"
                          component={StyledKeyboardDatePicker}
                          name="endDate"
                          minDate={moment.max(moment(values.startDate), moment()).startOf('day').toDate()}
                          minDateMessage={'The end date cannot occur before the start date.'}
                        />
                      </div>
                    </>
                  )}
                  <RadioGroupFormField
                    valueIsBoolean
                    name="changePattern"
                    disabled={isSubmitting}
                    options={[
                      {
                        label: 'Add/Remove Custom Dates',
                        value: true,
                      },
                    ]}
                  />
                  {values.changePattern && (
                    <div className={classes.dateTimePickers}>
                      <DatePickerMultiSelect
                        label="Event Dates"
                        placeholder="Select Event Dates"
                        datesOnScreen={4}
                        dates={values.dates as Date[]}
                        onChange={handleChangeMultiSelectDates}
                        error={errors.dates as string}
                        belowField={true}
                      />
                    </div>
                  )}
                </div>
              </FormModalScreen>
            </Form>
          )
        }}
      </Formik>
    </FormModal>
  )
}
