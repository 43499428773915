import React, { useMemo, useState } from 'react'
import { Button, Dialog, DialogContent, Grid, makeStyles } from '@material-ui/core'
import DatePickerCustom from '../../../../components/date-picker'
import { RecurrenceInfo } from 'Event/interfaces/interfaceCreateEditEvent'
import moment from 'moment'
import { useHistory } from 'react-router'
import useChangeEventScheduling from '../../../../hooks/useChangeEventScheduling'

const useStyles = makeStyles((theme: any) => ({
  container: {
    minHeight: '500px',
    padding: '20px',
  },
}))

interface Props {
  complete: () => void
  close: () => void
  cancel: () => void
  showing: boolean
  recurrenceInfo: RecurrenceInfo
  encodedOccurrence: string | number
}

export const EditEndDateModal = (props: Props) => {
  const { close, complete, cancel, showing, recurrenceInfo, encodedOccurrence } = props
  const history = useHistory()
  const { endDate, startDate } = recurrenceInfo
  const [newEndDate, setNewEndDate] = useState<string>(endDate)
  const changeEventScheduling = useChangeEventScheduling()
  const minDate = moment(startDate).isBefore(moment(), 'day') ? new Date() : startDate
  const endDateChanged = useMemo(() => newEndDate != endDate, [newEndDate, endDate])
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)
  const handleSubmit = async () => {
    if (endDateChanged) {
      setLoading(true)
      try {
        await changeEventScheduling(encodedOccurrence as string, { endDate: newEndDate })
        history.push(`/events/week`)
      } catch (e) {
      } finally {
        setLoading(false)
      }
    }
    complete()
  }
  return (
    <Dialog open={showing} onClose={close} fullWidth>
      <DialogContent className={classes.container}>
        <Grid container spacing={2} direction="column">
          <Grid item>
            <DatePickerCustom
              minDate={minDate}
              value={newEndDate}
              onChange={setNewEndDate}
              onBlur={setNewEndDate}
              placeholder="End date*"
            />
          </Grid>
          <Grid item>
            <Button onClick={cancel} variant="text" disabled={loading}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading || !endDateChanged}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
