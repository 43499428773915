import React, { useMemo } from 'react'
import { Field, useFormikContext } from 'formik'
import { useCurrentOrg } from 'auth/hooks'
import { yup } from 'civic-champs-shared/api/utils'
import { AddressField, StyledOutlinedTextField } from 'civic-champs-shared/formik/components'
import OrganizationModal from 'organization/component/OrganizationModal'
import RadioGroupFormField from 'components/RadioGroupFormField'
import { Tooltip, Typography } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/ContactSupportOutlined'
import { makeStyles } from '@material-ui/styles'
import { NumberSchema } from 'yup'
import { mapEventGeofencingToGeofencing } from '../../utils/event'

const validationSchema = yup.object({
  address: yup
    .object({
      line1: yup
        .string()
        .min(1, 'An address is required. Please enter an address.')
        .required('An address is required. Please enter an address.')
        .typeError('An address is required. Please enter an address.'),
    })
    .required('An address is required. Please enter an address.')
    .typeError('An address is required. Please enter an address.'),
  futureEventVisibilityDays: yup.mixed().when('limitFutureEventVisibility', {
    is: true,
    then: ((yup.number() as any).id() as NumberSchema).min(1).required('Please enter a number of days'),
    else: yup.mixed().notRequired().nullable(),
  }),
  replyToEmail: yup
    .string()
    .email('Please provide a valid email address from an accepted domain.')
    .required('Please provide a global reply-to email address for outbound messages.'),
})

const useStyles = makeStyles({
  subHeader: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
  },
  futureEventVisibilityDays: {
    width: '208px',
    marginLeft: '46px',
    marginTop: '-12px',
  },
})

const RenderIf = ({ children }: { children: React.ReactNode }) => {
  const { values } = useFormikContext<Record<string, any>>()
  if (values.limitFutureEventVisibility) {
    return <>{children}</>
  }
  return null
}

export default function OrganizationSettingsModal(props: {
  showing: boolean
  complete: (...props: any) => Promise<any>
  cancel: () => Promise<any>
}) {
  const org = useCurrentOrg()
  const initialValues = useMemo(
    () => ({
      address: {
        ...org.address,
        geofencing: mapEventGeofencingToGeofencing({ ...org, radius: 0 }),
      },
      sandboxModeEnabled: org.sandboxModeEnabled,
      limitFutureEventVisibility: org.limitFutureEventVisibility,
      futureEventVisibilityDays: org.futureEventVisibilityDays,
      replyToEmail: org.replyToEmail,
    }),
    [org],
  )
  const classes = useStyles()

  return (
    <OrganizationModal
      {...props}
      name="data"
      title="Edit Organization Settings"
      subtitle="Organization Settings"
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Field
        fullWidth
        variant="outlined"
        labelShrink
        label="Primary Address*"
        placeholder="Primary Address"
        component={AddressField}
        name="address"
        vertical
      />
      <Field
        component={StyledOutlinedTextField}
        id="replyToEmail"
        name="replyToEmail"
        label="Reply-To Email*"
        variant="outlined"
        fullWidth
      />

      <div className={classes.subHeader}>Event Visibility</div>
      <RadioGroupFormField
        name="limitFutureEventVisibility"
        valueIsBoolean
        options={[
          {
            label: 'Show all Upcoming Events on the organization’s event calendar',
            value: false,
          },
          {
            label: 'Limit how many days into the future volunteers can view events',
            value: true,
          },
        ]}
      />
      <RenderIf>
        <div className={classes.futureEventVisibilityDays}>
          <Field
            component={StyledOutlinedTextField}
            id="futureEventVisibilityDays"
            name="futureEventVisibilityDays"
            variant="outlined"
            label="Days"
            fullWidth
          />
        </div>
      </RenderIf>
      <div className={classes.subHeader}>
        Silent Mode
        <Tooltip
          title={
            <Typography style={{ fontSize: '16px' }}>
              Enabling Silent Mode will prevent administrative actions in Civic Champs from sending any emails or texts.
              This includes inviting volunteers to groups, registration confirmations, event cancellations, etc.
            </Typography>
          }
        >
          <HelpOutlineIcon style={{ fontSize: 18, verticalAlign: 'middle', marginLeft: 5 }} />
        </Tooltip>
      </div>
      <RadioGroupFormField
        name="sandboxModeEnabled"
        valueIsBoolean
        options={[
          {
            label: 'Disabled: All outbound messages will be sent as expected.',
            value: false,
          },
          {
            label: 'Enabled: Prevent all outbound messages from being sent.',
            value: true,
          },
        ]}
      />
    </OrganizationModal>
  )
}
